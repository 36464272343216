<template>
<div>
  <v-tooltip
    :bottom="bottom"
    :left="left"
    :open-delay="500"
    :right="right"
    :top="top"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        :color="color"
        v-bind="attrs"
        v-on="on"
      >{{ iconName }}</v-icon>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</div>
</template>

<script>
export default {
  props: {
    bottom: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    left: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: '',
    },
    right: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    top: {
      type: Boolean,
      default: false,
    },
  },
}
</script>